<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilCash" />
          Danh sách Chi Phí
        </span>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click.prevent="
            (e) => {
              editing = true;
              expenseId = 0;
            }
          "
        >
          <CIcon name="cil-plus" />
          Thêm Chi PHí
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="3">
            <CSelect
              label="Trạng thái"
              placeholder="Trạng thái"
              :value.sync="expenseStatus"
              :options="[
                { value: null, label: 'Tất cả trạng thái' },
                ...Object.keys(statusList).map((_) => {
                  return {
                    value: _,
                    label: statusList[_],
                  };
                }),
              ]"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
            />
          </CCol>
          <CCol md="3">
            <CSelect
              label="Loại phí"
              placeholder="Loại phí"
              :value.sync="expenseType"
              :options="[
                { value: null, label: 'Tất cả loại phí' },
                ...Object.keys(expenseTypeList).map((_) => {
                  return {
                    value: _,
                    label: expenseTypeList[_],
                  };
                }),
              ]"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
            />
          </CCol>
          <CCol md="3">
            <CInput
              label="Tên Chi Phí"
              placeholder="Tên Chi Phí"
              v-model="expenseName"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
              @keyup.enter="doFilter"
            />
          </CCol>
          <CCol md="3" class="text-center">
            <CButton color="primary" class="mr-2 mb-3" @click="doFilter">
              <CIcon name="cil-search" custom-classes="c-icon m-0" />
              Tìm kiếm
            </CButton>

            <CButton color="secondary" class="mb-3" @click="clear">
              <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
              Đặt lại
            </CButton>
          </CCol>
        </CRow>
        <odata-table
          ref="lstExpense"
          url="odata/Expense"
          :filter="filter"
          select="Id,Code,Name,ExpenseType,Unit,Note,Status,CreatedAt"
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Code: {
              field: 'Code',
              display: 'Mã Chi Phí',
              sortable: true,
              style: 'min-width: 70px',
            },
            Name: {
              field: 'Name',
              display: 'Tên Chi Phí',
              sortable: true,
              style: 'min-width: 150px',
            },
            ExpenseType: {
              field: 'ExpenseType',
              display: 'Loại phí',
              sortable: true,
              style: 'min-width: 120px',
            },
            Unit: {
              field: 'Unit',
              display: 'Đơn vị',
              sortable: true,
              style: 'min-width: 70px',
            },
            Note: {
              field: 'Note',
              display: 'Ghi chú',
              sortable: true,
              style: 'min-width: 150px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstExpense.page - 1) * pageSize }}.</td>
              <td>{{ row.Code }}</td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Cập nhật: ${row.Name}`"
                  @click="
                    (e) => {
                      expenseId = row.Id;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />
                  {{ row.Name }}
                </a>
              </td>
              <td>
                {{ $const.EXPENSE_TYPES_TEXT[row.ExpenseType] }}
              </td>
              <td>
                {{ row.Unit }}
              </td>
              <td class="text-truncate" style="max-width:150px;">
                <span :title="row.Note">
                  {{ row.Note }}
                </span>
              </td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .local()
                    .format("DD/MM/YY HH:mm")
                }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :expenseId.sync="expenseId"
      @reload="$refs.lstExpense.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";

export default {
  name: "Expense",
  components: {
    odataTable,
    detail,
  },
  data() {
    return {
      editing: false,
      expenseId: 0,
      pageSize: 10,
      expenseStatus: null,
      statusList: this.$const.STATUS_TEXT,
      expenseTypeList: this.$const.EXPENSE_TYPES_TEXT,
      expenseName: null,
      expenseType: null,
      filters: null,
    };
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },
    doFilter() {
      let filters = [];
      this.expenseStatus && filters.push(`Status eq ${this.expenseStatus}`);
      this.expenseName && filters.push(`contains(Name,'${this.expenseName}')`);
      this.expenseType && filters.push(`ExpenseType eq ${this.expenseType}`);
      filters.join(" and ");

      if (JSON.stringify(this.filters) != JSON.stringify(filters)) {
        this.filters = filters;
      } else {
        this.$refs.lstExpense.loadData();
      }
    },
    clear() {
      this.expenseStatus = null;
      this.expenseType = null;
      this.expenseName = null;
    },
  },
};
</script>
