var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.expense.Id ? 'Cập nhật Expense' : 'Thêm Expense',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"lg"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Mã*","placeholder":"Mã","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['expense.Code'],
            },"invalid-feedback":_vm.inValidObject['expense.Code'],"disabled":!!_vm.expense.Id},model:{value:(_vm.expense.Code),callback:function ($$v) {_vm.$set(_vm.expense, "Code", $$v)},expression:"expense.Code"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Tên Chi Phí*","placeholder":"Tên Chi Phí","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['expense.Name'],
            },"invalid-feedback":_vm.inValidObject['expense.Name']},model:{value:(_vm.expense.Name),callback:function ($$v) {_vm.$set(_vm.expense, "Name", $$v)},expression:"expense.Name"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CSelect',{attrs:{"label":"Loại phí*","placeholder":"Loại phí","value":_vm.expense.ExpenseType,"options":Object.keys(_vm.$const.EXPENSE_TYPES_TEXT).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.EXPENSE_TYPES_TEXT[_],
                };
              }),"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['expense.ExpenseType'],
            },"invalid-feedback":_vm.inValidObject['expense.ExpenseType']},on:{"update:value":function($event){return _vm.$set(_vm.expense, "ExpenseType", $event)}}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Đơn vị","placeholder":"Đơn vị","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['expense.Unit'],
            },"invalid-feedback":_vm.inValidObject['expense.Unit']},model:{value:(_vm.expense.Unit),callback:function ($$v) {_vm.$set(_vm.expense, "Unit", $$v)},expression:"expense.Unit"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Chọn Trạng thái","value":_vm.expense.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.STATUS_TEXT[_],
                };
              }),"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['expense.Status'],
            },"invalid-feedback":_vm.inValidObject['expense.Status']},on:{"update:value":function($event){return _vm.$set(_vm.expense, "Status", $event)}}})],1),_c('CCol',{attrs:{"md":"10"}},[_c('CInput',{attrs:{"label":"Ghi chú","placeholder":"Ghi chú","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['expense.Note'],
            },"invalid-feedback":_vm.inValidObject['expense.Note']},model:{value:(_vm.expense.Note),callback:function ($$v) {_vm.$set(_vm.expense, "Note", $$v)},expression:"expense.Note"}})],1)],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveExpense}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }