<template>
  <CModal
    :title="expense.Id ? 'Cập nhật Expense' : 'Thêm Expense'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="lg"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <div class="form-row">
          <CCol md="2">
            <CInput
              label="Mã*"
              placeholder="Mã"
              v-model="expense.Code"
              :add-input-classes="{
                'is-invalid': !!inValidObject['expense.Code'],
              }"
              :invalid-feedback="inValidObject['expense.Code']"
              :disabled="!!expense.Id"
            />
          </CCol>
          <CCol md="6">
            <CInput
              label="Tên Chi Phí*"
              placeholder="Tên Chi Phí"
              v-model="expense.Name"
              :add-input-classes="{
                'is-invalid': !!inValidObject['expense.Name'],
              }"
              :invalid-feedback="inValidObject['expense.Name']"
            />
          </CCol>
          <CCol md="2">
            <CSelect
              label="Loại phí*"
              placeholder="Loại phí"
              :value.sync="expense.ExpenseType"
              :options="
                Object.keys($const.EXPENSE_TYPES_TEXT).map((_) => {
                  return {
                    value: _,
                    label: $const.EXPENSE_TYPES_TEXT[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['expense.ExpenseType'],
              }"
              :invalid-feedback="inValidObject['expense.ExpenseType']"
            />
          </CCol>
          <CCol md="2">
            <CInput
              label="Đơn vị"
              placeholder="Đơn vị"
              v-model="expense.Unit"
              :add-input-classes="{
                'is-invalid': !!inValidObject['expense.Unit'],
              }"
              :invalid-feedback="inValidObject['expense.Unit']"
            />
          </CCol>
          <CCol md="2">
            <CSelect
              label="Trạng thái"
              placeholder="Chọn Trạng thái"
              :value.sync="expense.Status"
              :options="
                Object.keys($const.STATUS_TEXT).map((_) => {
                  return {
                    value: _,
                    label: $const.STATUS_TEXT[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['expense.Status'],
              }"
              :invalid-feedback="inValidObject['expense.Status']"
            />
          </CCol>
          <CCol md="10">
            <CInput
              label="Ghi chú"
              placeholder="Ghi chú"
              v-model="expense.Note"
              :add-input-classes="{
                'is-invalid': !!inValidObject['expense.Note'],
              }"
              :invalid-feedback="inValidObject['expense.Note']"
            />
          </CCol>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveExpense" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>
<script>
export default {
  props: ["editing", "expenseId"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      expense: this.newExpense(),
      saving: false,
    };
  },
  watch: {
    async expenseId(val) {
      await this.loadExpenseInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:expenseId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
    expenseType() {
      this.expense = this.newExpense();
    },
  },
  methods: {
    newExpense() {
      return {
        Id: 0,
        Code: null,
        Name: null,
        Note: null,
        ExpenseType: null,
        Unit: null,
        Status: this.$const.STATUS.Active.toString(),
      };
    },

    async loadExpenseInfo(expenseId) {
      try {
        this.inValidObject = {};
        if (expenseId) {
          let filters = [];
          expenseId && filters.push(`Id eq ${expenseId}`);
          let resp = await this.$http.get(`odata/Expense`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Code,Name,Note,ExpenseType,Unit,Status",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.expense = {
              Id: _.Id,
              Code: _.Code,
              Name: _.Name,
              Note: _.Note,
              ExpenseType: _.ExpenseType.toString(),
              Unit: _.Unit,
              Status: _.Status.toString(),
            };
          }
        } else {
          this.expense = this.newExpense();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveExpense() {
      let expense = this.expense;
      this.inValidObject = this.checkValid(expense);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let expenseData = {
          Name: expense.Name,
          Note: expense.Note,
          Status: parseInt(expense.Status),
          ExpenseType: parseInt(expense.ExpenseType),
          Unit: expense.Unit,
        };
        // Expense
        if (!expense.Id) {
          // post
          expenseData.Code = expense.Code;
          let respCheck = await this.$http.get(`odata/Expense`, {
            params: {
              $top: 1,
              $filter: `Value eq '${expenseData.Code}'`,
              $select: "Id",
            },
          });
          if (respCheck && respCheck.status == 200) {
            let respCheckData = respCheck.data.value;
            if (respCheckData.length) {
              alert(
                `Mã '${expenseData.Code}' đã được sử dụng. Vui lòng nhập mã khác!`
              );
            } else {
              let resp = await this.$http.post(`odata/Expense`, expenseData);
              if (resp && resp.status == 201) {
                this.detailModal = false;
                this.$emit("reload");
              }
            }
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/Expense/${expense.Id}`,
            expenseData
          );
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(expense) {
      let inValidObject = {};

      if (!expense.Code) inValidObject["expense.Code"] = "Vui lòng nhập Mã!";
      if (!expense.Name)
        inValidObject["expense.Name"] = "Vui lòng nhập Tên Chi phí!";
      if (!expense.Status)
        inValidObject["expense.Status"] = "Vui lòng chọn Trạng thái!";
      if (!expense.ExpenseType)
        inValidObject["expense.ExpenseType"] = "Vui lòng chọn Loại phí!";

      return inValidObject;
    },
  },
};
</script>
